import React from "react"
import Header from "../components/header"
import SEO from "../components/seo"


import Footer from "../components/footer"
require("typeface-montaga")
require("typeface-open-sans")
require("typeface-bitter")


export default ({ children }) => (
<>
<div id="container">
    < Header />

    <div id="content" >
        {children }
    </div>
</div>

< Footer />
</>
)