import React from "react"
import { Link } from "gatsby"
const ListLink = props => (
  <li style={{ display: `inline-block`, marginRight: `1rem` }}>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default() => 
(
<header>
    <h1>acornesque</h1>
    <span className="header-subtitle">lincoln's <del>dumb linkblog</del> site</span>
    <nav>
          <ListLink to="/">Home</ListLink>
          <ListLink to="/articles/">Articles</ListLink>
          <ListLink to="/about/">About</ListLink>
    </nav>
</header>
)