import React from "react"

export default() => 
<footer>
< Subject name="twitter" link="https://twitter.com/lphammond" excerpt="Site's a hot mess, but it sometimes makes me laugh." />
      < Subject name="pinboard" link="https://pinboard.in/u:acornesque" excerpt="Plenty of things I find worthy of reading twice." />
      < Subject name="github" link="https://github.com/rewardian" excerpt="Long time forker, first time pusher." />
      < Subject name="email" link="mailto:lincoln@acornesque.net" excerpt="It's email. Exciting stuff." />
      < Subject name="rss" link="/rss.xml" excerpt="We're taking it back! Except Joe Rogan, apparently." />
      < Subject name="résumé" link="/resume.pdf" excerpt="We're hiring!" />
</footer>


const Subject = props => (
    <span>
            <a href={props.link} title={props.excerpt}>
                {props.name}
            </a>
        </span>
    )