import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"

export default function About({ data }) {
  return (
  < Layout >
  <SEO 
          title="About"
          description={data.site.description}
/>
  <div className="index">
    <h2>If I Have To</h2>
    <p>Hi! I'm Lincoln Hammond—a pretty cool dude who lives in Austin, Texas.</p>
    <p>I love discussing politics, philosophy, technology, and (good) media. If you are some lone Yeti bursting at the seams to discuss the Globalist agenda, why systemd is nonsense, or whether the Synder cut will restore the DCU, I literally have nothing <a href="mailto:lincoln@acornesque.net">better to do</a>.</p>
    
    <p>The site is built in <a href="https://www.gatsbyjs.org/">GatsbyJS</a>.</p>
  <img src="/images/Acorn-Kawaii-83631.gif" alt="" />
 </div>
  </ Layout >
  )
}

export const query = graphql`
query {
  site {
    siteMetadata {
      title
      author
      description
    }
  }
}
`